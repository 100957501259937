import React from 'react';
import {MenuProvider} from './src/components/MenuContext';
import {AnimatePresence} from 'framer-motion';
import '@fontsource/heebo/400.css';
import '@fontsource/heebo/700.css';

import app from 'gatsby-plugin-firebase-v9.0';
import {getAnalytics, isSupported} from 'firebase/analytics';

// load firebase analytics
isSupported().then((result) => {
  if (result) {
    getAnalytics(app);
  }
});

export function wrapPageElement({element}) {
  return <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>;
}

export function wrapRootElement({element}) {
  return <MenuProvider>{element}</MenuProvider>;
}
